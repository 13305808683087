export enum HoverColorVariant {
  Red = "Red",
  Yellow = "Yellow",
  Green = "Green",
  Blue = "Blue"
}

export const getHoverClasses = (variant: string | HoverColorVariant): string => {
  const typedVariant = variant as HoverColorVariant;
  return {
    [HoverColorVariant.Red]: "hover:bg-red",
    [HoverColorVariant.Yellow]: "hover:bg-yellow",
    [HoverColorVariant.Green]: "hover:bg-green",
    [HoverColorVariant.Blue]: "hover:bg-blue",
  }[typedVariant] + " hover:text-black"
}

