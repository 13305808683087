//@ts-ignore
import React, {FC} from "react"
import {ArticlePreviewFields, ContentfulRemark} from "../../utils/common-types";
import {MarkdownContainer, SmHeader, XLHeader} from "../typography";
import Img from 'gatsby-image'
import {SplitArrowCarousel} from '../carousels';
import {Link} from "gatsby";
import {getHoverClasses, HoverColorVariant} from "../../utils/get-hover-classes";

const BlogSlide: FC<ArticlePreviewFields> = ({
                                               title,
                                               slug,
                                               heroImage,
                                               description,
                                               sys
                                             }) => {
  const prefix = sys.contentType.sys.id === 'solutionPage' ? 'solutions' : 'blog';
  return (
    <Link to={`/${prefix}/${slug}`}>
      <div className="text-white text-center">
        <Img alt="" fluid={heroImage.fluid} className="my-8"/>
        <SmHeader>{title}</SmHeader>
        <MarkdownContainer className="px-4 pb-16">
          {description.childMarkdownRemark.html}
        </MarkdownContainer>
      </div>
    </Link>
  )
}

interface SplitSectionBlogSliderProps {
  hoverColor: HoverColorVariant;
  headline: string;
  description: ContentfulRemark;
  featuredBlogPosts: ArticlePreviewFields[];
}

const SplitSectionBlogSlider: FC<SplitSectionBlogSliderProps> = ({
                                                                   headline,
                                                                   description,
                                                                   featuredBlogPosts,
                                                                   hoverColor
                                                                 }) => {
  return (
    <section className="border-t-2 border-b-2 border-gray-200 flex flex-col md:flex-row">
      <div
        className={`w-full text-white md:w-1/2 px-8 md:px-16 border-b-2 md:border-b-0 md:border-r-2 border-gray-200`}>
        <div className="h-full w-full flex flex-col justify-center items-center hover-yellow-text">
          <XLHeader className="w-full">{headline}</XLHeader>
          <MarkdownContainer className="py-8">
            {description.childMarkdownRemark.html}
          </MarkdownContainer>
        </div>
      </div>
      <div className="w-full md:w-1/2 px-8 md:px-16">
        <SplitArrowCarousel>
          {featuredBlogPosts.map((blog, index) => (
            <BlogSlide key={index} {...blog}/>
          ))}
        </SplitArrowCarousel>
      </div>
    </section>
  )
}

export default SplitSectionBlogSlider;
