import React from 'react'
import './campaign-ticker.css'

const CampaignTicker = ({campaignTickerText, campaignTickerLink}) => (
    <a href={campaignTickerLink} target="_blank" rel="noreferrer">
        <div className="marquee flex justify-center items-center">
            <div className="marquee-content">
                {[...Array(25)].map((val, ind) => (
                    <div className="marquee-item" key={ind}>{campaignTickerText}</div>
                ))}
            </div>
        </div>
    </a>
)

export default CampaignTicker;
