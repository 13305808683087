import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import CampaignTicker from "../components/landing-page-sections/campaign-ticker";
import {HeadlineBlurbCTASection, HeadlineBlurbSection} from "../components/sections";
// import HoverSolutionsGrid from "../components/landing-page-sections/hover-solutions-grid";
import SplitSectionBlogSlider from "../components/landing-page-sections/split-section-blog-slider";
import FeaturedSectionWithVideo from "../components/landing-page-sections/featured-section-video";
import LandingPageHero from "../components/landing-hero";
import {ArrowsCarousel} from "../components/carousels";
import ArticlePreview from '../components/article-preview'
import ScrollAnchor from "../components/scroll-anchor";
import { IGPosts } from "../components/landing-page-sections/embedded-ig";
import {HoverColorVariant} from "../utils/get-hover-classes";

class RootIndex extends React.Component {
  state = {
    loaded: false
  }

  componentDidMount() {
    this.setState({loaded: true})
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const landingPage = get(this, 'props.data.contentfulLandingPage')
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet><title>{siteTitle}</title></Helmet>
          <LandingPageHero
            mainSplashHeadline={landingPage.mainSplashHeadline}
            mainSplashDescription={landingPage.mainSplashDescription}
            mainSplashCtaLink={landingPage.mainSplashCtaLink}
            mainSplashCtaButtonText={landingPage.mainSplashCtaButtonText}
            mainSplashCarousel={landingPage.mainSplashCarousel}
          />
        </div>
        <CampaignTicker campaignTickerLink={landingPage.marqueeTickerLink} campaignTickerText={landingPage.marqueeTickerText}/>
        <HeadlineBlurbSection {...landingPage.firstBannerSectionHeadlineDescription} className="bg-green text-white" />
        {/*<HoverSolutionsGrid blockData={landingPage.equitySolutions}/>*/}
        <ScrollAnchor id="blog-slider"/>
        <section>
          <ArrowsCarousel headline={landingPage.blogCarouselHeader}>
            {landingPage.recentUpdatesArticles.map((post, index) => (
              <ArticlePreview key={index} article={post}/>
            ))}
          </ArrowsCarousel>
        </section>
        <ScrollAnchor id="equity-solutions"/>
        <SplitSectionBlogSlider {...landingPage.whatWeDoSection} hoverColor={HoverColorVariant.Yellow}/>
        <HeadlineBlurbCTASection {...landingPage.payTheFeeSection} className="bg-red text-white" useDonateButton/>
        <SplitSectionBlogSlider {...landingPage.whereWereGoingSection} hoverColor={HoverColorVariant.Green}/>
        <FeaturedSectionWithVideo {...landingPage.featuredHeadlineVideoSection} />
        <HeadlineBlurbCTASection {...landingPage.volunteerSection} className="bg-green text-white" />
        {/*<IGPosts featuredInstagramPosts={landingPage.featuredInstagramPosts}/>*/}
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLandingPage(contentful_id: {eq: "6vXPKQ0ozVXRBvNTt0GJZn"}) {
      mainSplashHeadline
      mainSplashDescription {
        childMarkdownRemark {
          html
        }
      }
      mainSplashCtaLink
      mainSplashCtaButtonText
      mainSplashCarousel {
        description
        file {
          url
          contentType
        }
      }
      marqueeTickerText
      marqueeTickerLink
      firstBannerSectionHeadlineDescription {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
      }
      blogCarouselHeader
      recentUpdatesArticles {
        ... on ContentfulBlogPost {
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulArticle {
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulCustomLinkTree {
          title
          slug
          overrideUrl
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      whatWeDoSection {
        headline
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredBlogPosts {
          ... on ContentfulBlogPost {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys { contentType { sys { id } } }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulSolutionPage {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys { contentType { sys { id } } }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      payTheFeeSection {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
        ctaButtonText
        ctaButtonLink
      }
      whereWereGoingSection {
        headline
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredBlogPosts {
          ... on ContentfulBlogPost {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys { contentType { sys { id } } }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulSolutionPage {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys { contentType { sys { id } } }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      featuredHeadlineVideoSection {
        headlineSection {
          headline
          blurb {
            childMarkdownRemark {
              html
            }
          }
        }
        youTubeLink
      }
      volunteerSection {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
        ctaButtonText
        ctaButtonLink
      }
      featuredInstagramPosts
    }
  }
`
