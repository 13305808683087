import {MarkdownContainer, XLHeader} from "./typography";
import {ButtonNewWindow, VariantRed} from "./buttons";
// import {DotsCarousel} from "./carousels";
import React, {useEffect, useState} from "react";

const LargeVideo = ({url}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  return (
    <video autoPlay muted controls loop playsInline
           className={`focus:outline-none w-full opacity-100`}
           onLoadedData={onLoadedData}>
      <source src="https://d2j8oo2nydfmnp.cloudfront.net/sizzle-less-cropped.m4v" type="video/mp4"/>
    </video>
  )
}

const LandingPageHero = ({mainSplashHeadline, mainSplashDescription, mainSplashCtaLink, mainSplashCtaButtonText, mainSplashCarousel}) => {
  const [displayContent, setDisplayContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplayContent(true)
    }, 150)
  }, [])

  return (
    <div className="flex flex-col md:flex-row md:min-h-3/4-screen">
      <div className={`w-full md:w-1/2 bg-texture md:border-r-2 md:border-gray-200 flex justify-center items-center py-16`}>
        <div className={`px-8 md:px-16 ${displayContent ? 'opacity-100': 'opacity-0'} transition-opacity text-white`}>
          <XLHeader>{mainSplashHeadline}</XLHeader>
          <MarkdownContainer className="text-white py-8">
            {mainSplashDescription.childMarkdownRemark.html}
          </MarkdownContainer>
          <ButtonNewWindow to={mainSplashCtaLink} variant={VariantRed}>{mainSplashCtaButtonText}</ButtonNewWindow>
        </div>
      </div>

      <div className={`w-full md:w-1/2 ${displayContent ? 'opacity-100': 'opacity-0'} transition-opacity`}>
        <LargeVideo/>
        {/*<video autoPlay muted controls loop playsInline className="focus:outline-none w-full">*/}
        {/*  <source src="https://kcen-public-images.s3.amazonaws.com/sizzle-less-cropped.m4v" type="video/mp4"/>*/}
        {/*</video>*/}
      </div>
    </div>
  )
}

export default LandingPageHero;
