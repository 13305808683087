// a.anchor {
//     display: block;
//     position: relative;
//     top: -250px;
//     visibility: hidden;
// }
//@ts-ignore
import React, {FC} from "react";

const ScrollAnchor: FC<{id: string}> = ({id}) => (<a className="block relative invisible -top-20" id={id}/>)

export default ScrollAnchor;
